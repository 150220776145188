<template>
    <user-info-layout>
        <template #heading-text>How did you hear about us?</template>
        <v-form
            ref="form"
            lazy-validation
            v-model="valid"
            @submit.prevent="handleSubmit"
        >
            <v-select
                v-model="referenceSelector"
                label="Reference"
                :menu-props="{bottom: true, offsetY: true}"
                outlined
                :items="options"
                :rules="selectRules"
                validate-on-blur
            ></v-select>
            <v-text-field
                v-if="isOther"
                v-model="referenceText"
                label="Please specify"
                class="mt-4"
                outlined
                :counter="100"
                :rules="textRules"
                validate-on-blur
            ></v-text-field>
            <v-btn
                large
                class="mt-4"
                :loading="loading"
                :disable="loading"
                :elevation="0"
                :width="$vuetify.breakpoint.xs ? '100%' : '25%'"
                color="primary"
                @click="handleSubmit"
            >
                Get Started
                <v-icon
                    right 
                >
                    mdi-check-circle-outline
                </v-icon>
            </v-btn>
        </v-form>
    </user-info-layout>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import UserInfoLayout from '../../layouts/UserInfoLayout.vue';

export default {
    name: "Reference",
    components: {
        UserInfoLayout,
    },
    computed: {
        ...mapGetters({
            reference: 'userInfoForm/reference',
        }),
        referenceText: {
            get() {
                return this.reference;
            },
            set(val) {
                this.setReference(val);
            }
        },
        referenceSelector: {
            get() {
                if(this.isOther) {
                    return 'Other';
                }
                return this.reference;
            },
            set(val) {
                if(val === 'Other') {
                    this.setReference('');
                } else {
                    this.setReference(val);
                }
            }
        },
        isOther() {
            if(this.reference !== null) {
                const slicedOptions = this.options.slice(0, this.options.length-1);
                const item = slicedOptions.find(option => option === this.reference);
                return !item;
            }
            return false;
        },
    },
    data() {
        return {
            loading: false,
            options: [
                'Youtube',
                'Recommendation',
                'Social media post',
                'Online search',
                'Filling in someone else\'s voiceform',
                'Article or link on the website',
                'Other',
            ],
            valid: true,
            selectRules: [
                value => !!value || 'Required'
            ],
            textRules: [
                value => !!value || 'Required',
                value => (value && value.length <= 100) || "Word limit exceeded",
            ]
        };
    },
    methods: {
        ...mapMutations({
            setReference: 'userInfoForm/setReference',
        }),
        ...mapActions({
            updateUserInfo: 'userInfoForm/updateUserInfo',
        }),
        async handleSubmit() {
            try {
                const valid = this.$refs.form.validate();
                if(!valid) {
                    return;
                }
                this.loading = true;
                const res = await this.updateUserInfo();
                this.loading = false;
                if(!res) {
                    return this.$router.replace({ name: 'user-info'})
                }
                if (res.data && res.data.user_info_updated) {
                    if(res.data.user && res.data.user.limited_time_offer && new Date(res.data.user.limited_time_offer).getTime() > new Date().getTime()){
                        return this.$router.push({name: 'dashboard.upgrade'})
                    }
                    if(res.data.user && res.data.user.preselected_price_plan_id){
                        return this.$router.push({name: 'dashboard.checkout', params: {
                            planId: res.data.user.preselected_price_plan_id
                        }, query: {nav_close: true}});
                    }
                    return this.$router.push({ name: 'dashboard.index'});
                }
            } catch(error) {
                this.loading = false;
                console.error("handleSubmitError: ", error);
            }
            return;
        },
    },
};
</script>

<style scoped lang="scss">
    .v-select {
        width: 65%;
    }
    .v-text-field {
        width: 65%;
    }
</style>
